import "./styles.scss";

import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import LazyLoad from "react-lazy-load";

const NewsTile = ({ thumbnail, title, link, newest }) => {
  return (
    <Link
      to={link}
      className={classNames(`news-tile`, {
        [`news-tile-small--newest`]: newest,
      })}
    >
      <LazyLoad>
        <div
          className="news-tile__thumbnail"
          style={{ backgroundImage: `url(${thumbnail})` }}
        />
      </LazyLoad>
      <div className="news-tile__right-content">
        <h3 className="news-tile__title">{title}</h3>
        <span className="arrow-link">Czytaj dalej</span>
      </div>
    </Link>
  );
};

export default NewsTile;
