import "./post-categories.scss";

import React from "react";
import { Link } from "gatsby";

const PostCategories = ({ categories }) => {
  return (
    <div className="post-categories">
      <div className="post-categories__text">
        <img
          src={require("assets/icons/categories.svg").default}
          alt=""
          style={{ marginRight: 10 }}
        />
        <span>Kategorie:</span>
      </div>
      <div className="post-categories__categories-row">
        {categories?.map((item, index) => (
          <Link
            to={`/blog/${item.slug}/`}
            key={index}
            className={`current ${item.slug}`}
          >
            {item.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default PostCategories;
