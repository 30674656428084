import "./post-author.scss";

import React from "react";

const PostAuthor = ({ avatar, name }) => {
  return (
    <div className="post-author">
      {avatar && (
        <img src={avatar} alt="" className="post-author__avatar img-fluid" />
      )}
      {name && <div className="post-author__name">{name}</div>}
    </div>
  );
};

export default PostAuthor;
