import "./post-date.scss";

import React from "react";

const PostDate = ({ date }) => {
  return (
    <div className="post-date">
      <img
        src={require("assets/icons/calendar.svg").default}
        alt=""
        className="post-date__icon"
      />
      <div>Data publikacji: {date}</div>
    </div>
  );
};

export default PostDate;
