import "./styles.scss";

import React from "react";
import Slider from "react-slick";

import { usePosts } from "context/posts";

import NewsTile from "components/NewsTile";

const PostOther = ({ category }) => {
  const { posts } = usePosts();

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    fade: false,
    responsive: [
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="post-other">
      <h2>Zobacz również</h2>
      <Slider {...sliderSettings}>
        {posts
          ?.filter((item) =>
            item?.categories?.nodes?.some((cat) => cat.slug === category?.slug)
          )
          .slice(0, 6)
          ?.map((item, index) => (
            <div key={index}>
              <NewsTile
                newest={index === 0}
                title={item.title}
                description={item.excerpt}
                thumbnail={item.featuredImage?.node.sourceUrl}
                link={item.uri}
              />
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default PostOther;
